import { configureStore, createSlice } from '@reduxjs/toolkit';
import home from '../Home/home';
import about from '../About/about';
import states from '../States/states';
import contact from '../Contact/contact';
import blog from '../Blog/blog';
import guide from '../Guide/guide';

const initialState = {
  username: localStorage.getItem("arab_user_name")
    ? localStorage.getItem("arab_user_name")
    : 'Guest',
  newField: null,
  isLoading: true
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsername: (state, action) => {
      state.username = action.payload;
    },
    setNewField: (state, action) => {
      state.newField = action.payload;
    },
    setLoading: (state, actions) => {
      state.isLoading = actions.payload;
    }
  },
});

export const { setUsername, setNewField, setLoading } = userSlice.actions;

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    home: home,
    about: about,
    states: states,
    contact: contact,
    blog: blog,
    guide:guide
  },
  setNewField: (state, action) => {
    state.newField = action.payload;
  },
});

export default store;
