import React, { useEffect, useState, lazy, Suspense } from "react";
import { Crisp } from "crisp-sdk-web";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/layout/navbar/Navbar";
import Footer from "./components/layout/footer/Footer";
import { useTranslation } from "react-i18next";
import OneSignal from "react-onesignal";
import ScrollToTop from "./components/common/ScrollToTop";
import EulaGeorgiaPage from "./pages/EulaGeorgiaPage";
import MetaPixel from "./Utils/MetaPixel";
import ScrollToTopButton from "./components/common/ScrollToTopButton";
import PrivateRoutes from "./Utils/PrivateRoutes";
import useAxios from "./hooks/useAxiosGet";
import GetLang from "./Utils/language/GetLang";
import { useSelector } from 'react-redux';

const Home = lazy(() => import("./pages/Home"));
const UserProfilePage = lazy(() => import("./pages/UserProfilePage"));
const DeleteAccountPage = lazy(() => import("./pages/DeleteAccountPage"));
const Blog = lazy(() => import("./pages/Blog"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const Contact = lazy(() => import("./pages/ContactUs"))
const Page404 = lazy(() => import("./pages/Page404"));
const PrivacyPolicy = lazy(() => import("./components/legal/PrivacyPolicy"));
const Terms_conditions = lazy(() => import("./components/legal/Terms_conditions"));
const ShowBlog = lazy(() => import('./pages/ShowBlog'));
const About = lazy(() => import('./pages/AboutPage'));
const ChangePassword = lazy(() => import("./components/userProfile/ChangePassword"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const UserGuide = lazy(() => import("./pages/UserGuidePage"));
const ShowUserGuide = lazy(() => import("./pages/ShowUserGuide"));
const SearchResultPage = lazy(() => import("./pages/SearchResultPage"));
const JotForm = lazy(() => import("./pages/JotFormPage"));
const CityHome = lazy(() => import('./pages/multiCity/Home'));
const States = lazy(() => import("./pages/States"));
const Spinner = lazy(() => import("./Utils/SpinnerFunction"));

function App() {
  let generalUrl = "general-setting";
  let authUrl = "login-page";
  const [Data] = useAxios(generalUrl);
  const [authData] = useAxios(authUrl);
  const isLoadingRedux = useSelector((state) => state.user.isLoading);


  const logoImage = Data?.data?.navbar?.logo;
  const spinnerLogo = Data?.data?.icon_loading;
  const authImage = authData?.data?.login_page?.image;

  const [baseURL, setBaseURL] = useState();
  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONE_SIGNAL_KEY,
    });
  }, []);

  const [t] = useTranslation();

  const autherized = localStorage.getItem("arab_user_token");

  const cityId = localStorage.getItem("cityId");
  let cityIdUrl = "/0";
  useEffect(() => {
    if (cityId) {
      cityIdUrl = `/${cityId}`;
    } else {
      cityIdUrl = "/0";
    }
  }, [cityId]);

  const authAPI = `https://${process.env.REACT_APP_domain}/api/${process.env.REACT_APP_City
    }/${t("en")}${cityIdUrl}`;
  const guestAPI = `https://${process.env.REACT_APP_domain}/api/${process.env.REACT_APP_City
    }/${t("en")}${cityIdUrl}`;

  useEffect(() => {
    autherized ? setBaseURL(authAPI) : setBaseURL(guestAPI);
  }, [autherized]);

  const login_api = `https://${process.env.REACT_APP_domain}/api/${process.env.REACT_APP_City}/en${cityIdUrl}/login`;
  const regester_api = `${baseURL}/register`;
  const profile_api = `${baseURL}/profile`;
  const blogs_api = `${baseURL}/blogs`;
  const fav_api = `${baseURL}/profile/favorite`;
  const saved_api = `${baseURL}/profile/save`;

  useEffect(() => {
    Crisp.configure(process.env.REACT_APP_CRISP_KEY);
  }, []);

  const token = localStorage.getItem("arab_user_token");
  const userName = localStorage.getItem("arab_user_name");
  const userEmail = localStorage.getItem("arab_user_email");

  useEffect(() => {
    if (token) {
      Crisp.setTokenId(token);
      Crisp.user.setEmail(userEmail);
      Crisp.user.setNickname(userName);
    } else {
      Crisp.user.setNickname("Guest");
    }
  }, []);

  const [lang] = GetLang();

  return (
    <>
      <Router basename={`/${lang}`}>
        {isLoadingRedux ? (
          <>    <Spinner logo={spinnerLogo} />
          </>
        ) : (
          <>
          </>
        )}

        <MetaPixel />
        <ScrollToTop />
        <ScrollToTopButton />
        <Navbar
          url={login_api}
          regesterUrl={regester_api}
          logoImage={logoImage}
        />
        <Suspense fallback={<Spinner />}>
          <main className="mainSection">
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route
                  path={`/Profile`}
                  element={
                    <UserProfilePage
                      url={profile_api}
                      fav={fav_api}
                      saved={saved_api}
                    />
                  }
                />
                <Route
                  path={`/delete-account`}
                  element={
                    <DeleteAccountPage baseUrl={authAPI} logo={authImage} />
                  }
                />
                <Route
                  path={`/changePassword`}
                  element={<ChangePassword baseUrl={authAPI} logo={authImage} />}
                />
              </Route>

              <Route path={``} element={<Home baseURL={baseURL} />} />
              <Route path={`/home`} element={<CityHome baseURL={baseURL} />} />
              <Route exact path={`/Blog`} element={<Blog url={blogs_api} />} />
              <Route
                exact
                path={`/Contact`}
                element={<Contact baseURL={guestAPI} />}
              />
              <Route
                exact
                path={`/Login`}
                element={<Login baseURL={guestAPI} logo={authImage} />}
              />
              <Route
                exact
                path={`/Register`}
                element={<Register baseURL={baseURL} logo={authImage} />}
              />
              <Route
                exact
                path={`/search-result/:keyword/:type?`}
                element={<SearchResultPage />}
              />
              <Route exact path={`/Privacy-Policy`} element={<PrivacyPolicy />} />
              <Route
                exact
                path={`/Terms-conditions`}
                element={<Terms_conditions />}
              />

              <Route
                exact
                path={`/Show-Blog/:id?/:slug?`}
                element={<ShowBlog />}
              />
              <Route exact path={`/About`} element={<About />} />
              <Route
                exact
                path={`/Forget-Password`}
                element={<ForgetPassword baseURL={baseURL} logo={authImage} />}
              />
              <Route exact path={`/eula`} element={<EulaGeorgiaPage />} />
              <Route
                exact
                path={`/User-Guide`}
                element={<UserGuide url={blogs_api} />}
              />
              <Route
                exact
                path={`/Show-User-Guide/:id/:slug?`}
                element={<ShowUserGuide />}
              />
              <Route exact path={`/States`} element={<States />} />
              <Route exact path={`/form/:slug`} element={<JotForm />} />
              <Route exact path="/*" element={<Page404 />} />
            </Routes>

            {/* <Chat/> */}
          </main>
        </Suspense>
        <Footer
          logoImage={logoImage}
        />
      </Router>
    </>
  );
}

export default App;
