import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    statesData: null
}

const statesRedux = createSlice({
    name: "states",
    initialState,
    reducers: {
        setStatesData: (state, actions) => {
            state.statesData = actions.payload;
        }
    }
});

export const statesExports = ((state) => state.states);

export const { setStatesData } = statesRedux.actions;

export default statesRedux.reducer;