import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    guideData: null
};

const guideRedux = createSlice({
    name: "guide",
    initialState,
    reducers: {
        setGuideData: (state, action) => {
            state.guideData = action.payload;
        }
    }
});

export const guideState = ((state)=>state.guide);

export const { setGuideData } = guideRedux.actions;

export default guideRedux.reducer;